import { Background, FasfillImage2, Icon3 } from "../assets";

const FasfillSection = () => {
  return (
    <>
      <div>
        <img src={Background} alt="" className="w-full h-full" />
      </div>
      <section id="Fasfill" className="[calc(100vh)] bg-[#E8F1FF] flex items-center">
        <div className="w-full pt-10 lg:pt-0 lg:w-[80%] mx-auto h-full">
          <div className="grid lg:grid-cols-2 gap-12 h-full items-center">
            {/* Left Content (Text) */}
            <div className="space-y-4 px-6 lg:px-8">
              <div className="flex gap-4">
                <img src={Icon3} alt="" className="w-[20%] h-auto" />
                <h1 className="text-4xl lg:text-6xl font-bold text-[#002154] ">
                  Fasfill
                </h1>
              </div>
              <div className="flex gap-2 text-sm items-center mb-5">
                <p className="uppercase tracking-wide text-gray-400">
                  Mobile App
                </p>
                <div className="h-2 w-2 bg-gray-400 rounded-full"></div>
                <p className="uppercase tracking-wide text-gray-400">
                  Communication
                </p>
                <div className="h-2 w-2 bg-gray-400 rounded-full"></div>
                <p className="uppercase tracking-wide text-gray-400">
                  Business
                </p>
              </div>
              <p className="text-lg text-gray-700 mb-4">
                Fastill connects individuals and businesses in your city. It
                allows users to shop local, host events, and advertise their
                business. Fasfill offers additional features like local news
                updates and live audio sessions.
              </p>
              <p className="text-lg text-gray-700 mb-6">
                Fasfill will be available both in Android and iOS stores.
              </p>
              <a
                href="https://fasfill.com" target="blank"
                className="inline-flex gap-2 items-center bg-primary text-white text-lg px-10 py-4 rounded hover:bg-blue-700 transition"
              >
                Join Waitlist <span className="text-2xl">→</span>
              </a>
            </div>

            {/* Right Content (Images at Bottom) */}
            <div className="relative w-full h-full flex justify-center items-end">
              {/* <div className="relative">
                <img
                  src={Vector1}
                  alt="Platform Overview"
                  className="w-[80%] h-auto rounded-lg"
                />
                <img
                  src={FasfillImage2}
                  alt="Overlay Image"
                  className="absolute bottom-0 w-full h-full object-contain rounded-lg" // Set to 100% width and height
                />
              </div> */}
              <img src={FasfillImage2} alt="" className="w-[70%] lg:w-full"/>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FasfillSection;
