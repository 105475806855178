// import { Link } from "react-router-dom";
import { AgrivestImage, Icon4 } from "../assets";

const AgrivestSection = () => {
  return (
    <>
      <section id="Agrivest" className="bg-[#EDF5ED] [calc(100vh)] lg:h-screen pt-20 lg:p-0">
        <div className="items-center">
          <div className="flex flex-col-reverse lg:grid grid-cols-2 items-center gap-10">
            <div className="lg:relative w-full flex items-end justify-center">
                <img
                  src={AgrivestImage}
                  alt=""
                  className="w-full max-h-min object-cover"
                />
            </div>

            <div className="px-5 lg:pr-80">
              <div className="flex gap-4">
                <img src={Icon4} alt="" className="w-[20%] h-auto" />
                <h1 className="text-4xl lg:text-6xl font-bold text-[#002913] ">
                  Agrivest247
                </h1>
              </div>
              <div>
                <div className="flex gap-3 text-sm items-center mb-5">
                  <p className="uppercase tracking-wide text-gray-400">
                    Web App
                  </p>
                  <div className="h-2 w-2 bg-gray-400 rounded-full"></div>

                  <p className="uppercase tracking-wide text-gray-400">
                    Farming
                  </p>
                  <div className="h-2 w-2 bg-gray-400 rounded-full"></div>

                  <p className="uppercase tracking-wide text-gray-400">
                    Finance
                  </p>
                </div>
                <p className="text-lg text-gray-700 mb-8">
                  Agrivest247 is a web app that connects farmers with consumers
                  and investors. Users can invest early in farms, crop swap, and
                  buy/sell agricultural products directly from the marketplace.
                </p>
                <a
                  href="https://www.agrivest247.com" target="blank"
                  className="inline-flex gap-2 items-center bg-[#30A400] text-white text-lg px-10 py-4 rounded hover:bg-[#31a400c7] transition"
                >
                  Get Started <span className="text-2xl">→</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="bg-white">
        <div className="flex md:flex-row flex-col py-24">
          <div className="overflow-x-hidden">
            <img src={AgrivestImage} alt="" />
          </div>
          <div className="w">
            
          </div>
        </div>
      </section> */}
    </>
  );
};

export default AgrivestSection;
