import { GridImage } from "../assets";
import { Header } from "../components";
import ContactSection from "../ui/Contact";
const ContactUs = () => {
  return (
    <>
      <Header />
      <section className="bg-white w-full mx-auto relative flex flex-col items-center justify-center pb-20 lg:h-screen">
        {/* Add GridImage as background */}
        <div className="absolute inset-0 z-0">
          <img
            src={GridImage}
            alt="Grid Background"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="z-20 bg-white">

        <ContactSection />
        </div>
      </section>
    </>
  );
};

export default ContactUs;
