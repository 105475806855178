import { GridImage, Icon1, Icon2 } from "../assets";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { ScrollParallax } from "react-just-parallax";

const HeroSection = () => {
  return (
    <section
      id="Hero"
      className="h-[calc(100vh-100px)] flex items-center relative"
    >
      <div className="absolute inset-0 z-0">
        <img
          src={GridImage}
          alt="Grid Background"
          className="w-full h-full object-cover"
        />
      </div>

      <div className="w-[95%] md:w-[80%] mx-auto relative z-10">
        {" "}
        {/* Set relative z-index here */}
        <div
            className="relative text-center space-y-4 lg:space-y-10"
        >
          <div
              className="inline-flex border border-black/10 bg-[#ECECEC] text-sm px-4 py-2 rounded-full"
          >
            Backed by a team of experts
          </div>
          <h1 className="h1">
            Our Products
          </h1>
          <p
              className="lg:hidden text-black text-lg md:text-xl"
          >
            We build innovative platforms designed to <span className="font-bold"> create wealth</span> and drive
            solutions for individuals and businesses
          </p>
          <p
              className="hidden lg:block text-black text-lg lg:text-2xl"
          >
            We build innovative platforms designed to <span className="font-bold"> create wealth</span> and drive <br />
            solutions for individuals and businesses
          </p>
        
          <AnchorLink
              href="#Fasfill"
              className="inline-flex gap-2 z-50 items-center bg-primary text-white px-10 py-4 rounded hover:bg-blue-700 transition relative" // Set relative positioning to ensure it's on top
          >
            View <span>↓</span>
          </AnchorLink>

          {/* Floating Icons */}
          <ScrollParallax isAbsolutelyPositioned>
            <ul className="hidden left-[5rem] bottom-[7.5rem] bg-n-9/40 backdrop-blur rounded-2xl xl:flex z-10">
              {" "}
              {/* Set z-index here */}
              <img
                  src={Icon2}
                  alt="Floating Icon 2"
                  className="top-[0px] absolute"
              />
            </ul>
          </ScrollParallax>

          <ScrollParallax isAbsolutelyPositioned>
            <ul className="hidden absolute top-2/5 right-[5rem] bottom-[2rem] bg-n-9/40 backdrop-blur rounded-2xl xl:flex z-10">
              {" "}
              {/* Set z-index here */}
              <img src={Icon1} alt="Floating Icon 1"/>
            </ul>
          </ScrollParallax>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
