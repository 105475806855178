import { GridImage2, Vector3 } from "../assets";
// import AnchorLink from "react-anchor-link-smooth-scroll";

const InvestmentSection = () => {
  return (
    <>
      <section
        className="w-[90%] lg:w-full mx-auto bg-white bg-cover h-[700px] lg:h-[calc(100vh)] flex justify-center items-center"
        id="Investment"
        style={{ backgroundImage: `url(${GridImage2})` }}
      >
        <div className="relative z-1 max-w-[62rem] mx-auto text-center mb-[3.875rem]">
          <h1 className="text-4xl lg:text-6xl lg:leading-snug font-bold">
            You can now&nbsp;{` `}
            <span className="inline-block relative">
              invest{" "}
              <img
                src={Vector3}
                className="absolute top-full left-0 w-full"
                width={624}
                height={28}
                alt="Curve"
              />
            </span>
            <br /> with Gridhall
          </h1>
          <p className=" lg:text-lg text-gray-600 mb-8">
            Gridhall Incorporated is a technology company leading the way in
            innovation. We&apos;re inviting <br />
            investors to join us on this exciting journey. Be part of a company
            poised for growth and <br />
            success in the tech industry.
          </p>
          <a
            href="/Investment/"
            smooth={true}
            duration={800}
            className="inline-flex gap-2 px-10 py-4 bg-primary text-white rounded hover:bg-blue-700 cursor-pointer"
          >
            Get Started <span>↓</span>
          </a>
        </div>
      </section>
    </>
  );
};

export default InvestmentSection;
