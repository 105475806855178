// import { useState, useEffect, useCallback } from "react";
// import { Link, NavLink } from "react-router-dom";
// import { NavLinks } from "../constants";
// import { Logo } from "../assets";
// import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
// import { BsLinkedin, BsTwitterX } from "react-icons/bs";

// // Remove unused import
// // import { motion } from "framer-motion";

// const Header = () => {
//   const [toggle, setToggle] = useState(false);
//   const [lastScrollY, setLastScrollY] = useState(0);
//   const [visible, setVisible] = useState(true);
//   const [isScrolled, setIsScrolled] = useState(false); // Added state for scroll detection

//   const handleScroll = useCallback(() => {
//     if (window.scrollY < lastScrollY) {
//       setVisible(true);
//     } else {
//       setVisible(false);
//     }
//     setLastScrollY(window.scrollY);
//     setIsScrolled(window.scrollY > 0);
//   }, [lastScrollY]);

//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, [handleScroll]);

//   return (
//     <nav
//       className={`w-full flex items-center transition-transform duration-300 ease-in-out ${
//         isScrolled ? "bg-white/30 backdrop-blur-sm" : "bg-transparent"
//       } top-0 z-30 fixed ${visible ? "translate-y-0" : "-translate-y-full"}`}
//     >
//       <div
//         className={`w-[90%] md:w-[80%] mx-auto hidden md:flex justify-between items-center sticky z-10 py-5 top-0`}
//       >
//         <div className={`flex gap-4 items-center`}>
//           <Link to="/Products" className="flex items-center">
//             <img src={Logo} alt="logo" className={`w-full`} />
//           </Link>
//         </div>
//         <div className="hidden md:flex gap-10">
//           {NavLinks.map((nav, index) => (
//             <li key={index} className="list-none text-md">
//               <NavLink
//                 to={nav.url}
//                 className={({ isActive }) =>
//                   isActive
//                     ? "border-b-2 border-primary pb-2 font-semibold"
//                     : "pb-2"
//                 }
//               >
//                 {nav.title}
//               </NavLink>
//             </li>
//           ))}
//         </div>

//         <div className="flex">
//           <Link
//             to="/ContactUs"
//             className="bg-primary text-white px-10 py-4 rounded hover:bg-blue-700"
//           >
//             Contact Us
//           </Link>
//         </div>
//       </div>

//       {/* Mobile Nav */}
//       <div
//         className={`w-full flex md:hidden justify-between items-center py-4`}
//       >
//         <div className={`flex gap-4 items-center pl-5`}>
//           <Link to="/" className="flex items-center">
//             <img src={Logo} alt="logo" className={`w-full`} />
//           </Link>
//         </div>
//         <div className={`flex pr-5`}>
//           {toggle ? (
//             <AiOutlineClose
//               className="text-3xl cursor-pointer z-20"
//               style={{ color: "white" }}
//               onClick={() => setToggle(!toggle)}
//             />
//           ) : (
//             <AiOutlineMenu
//               className="text-3xl cursor-pointer"
//               onClick={() => setToggle(!toggle)}
//               aria-label="Toggle mobile menu"
//             />
//           )}

//           <div
//             className={`${
//               !toggle ? "hidden" : ""
//             } bg-black text-white p-6 absolute right-0 top-0 w-[80vw] h-screen flex flex-col justify-center transition-transform duration-300 ease-in-out transform`}
//           >
//             {NavLinks.map((nav, index) => (
//               <li
//                 key={index}
//                 className={`list-none text-2xl py-2`}
//                 onClick={() => {
//                   setToggle(!toggle);
//                 }}
//               >
//                 <NavLink
//                   to={nav.url}
//                   className={({ isActive }) =>
//                     isActive ? "border-b-2 border-primary pb-1" : ""
//                   }
//                 >
//                   {nav.title}
//                 </NavLink>
//               </li>
//             ))}
//             <div className="flex mt-20">
//               <Link
//                 to="/ContactUs"
//                 className=" bg-primary text-white px-4 py-2 rounded hover:bg-blue-700"
//               >
//                 Contact Us
//               </Link>
//             </div>
//             <div className="py-10">
//               <h1 className="text-xl text-gray-500">
//                 Follow us on social media
//               </h1>
//               <div className="pt-2 flex">
//                 <ul className="flex gap-2">
//                   <li className="bg-primary p-3 rounded-full">
//                     <button
//                       aria-label="Twitter"
//                       className="flex items-center"
//                       onClick={() =>
//                         window.open("https://x.com/GridHall", "_blank")
//                       }
//                     >
//                       <BsTwitterX size={20} color="white" />
//                     </button>
//                   </li>
//                   <li className="bg-primary p-3 rounded-full">
//                     <button
//                       aria-label="LinkedIn"
//                       className="flex items-center"
//                       onClick={() =>
//                         window.open(
//                           "https://www.linkedin.com/company/gridhall-limited/",
//                           "_blank"
//                         )
//                       }
//                     >
//                       <BsLinkedin size={20} color="white" />
//                     </button>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Header;

import React, { useState } from 'react';
import { NavLinks } from '../constants';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Logo } from '../assets';
import { useLocation } from 'react-router-dom';

const Header = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const location = useLocation();

    return (
        <header className="bg-white/30 backdrop-blur-sm sticky top-0 z-50">
            <nav className="w-[90%] lg:w-[85%] mx-auto flex items-center justify-between py-6" aria-label="Global">
                {/* Logo */}
                <a href="/Products" className="flex lg:flex-1 gap-6 -m-1.5 p-1.5">
                    <img src={Logo} alt="logo" className="md:w-[200px] w-[120px]" />
                </a>

                {/* Desktop Navigation */}
                <div className="hidden lg:flex lg:gap-x-12">
                    {NavLinks.map(({ title, url }) => (
                        <a
                            key={title}
                            href={url}
                            className={`text-md font-medium leading-6 ${
                                location.pathname === url ? 'text-black' : 'hover:text-primary'
                            }`}
                        >
                            {title}
                        </a>
                    ))}
                </div>

                {/* Call to Action */}
                <div className="hidden lg:flex lg:flex-1 lg:justify-end z-20">
                    <button
                        className="py-1 px-2 md:py-3 md:px-8 bg-primary text-lg text-white rounded"
                    >
                        Contact Us
                    </button>
                    
                </div>

                {/* Mobile Menu Button */}
                <button
                    type="button"
                    className="lg:hidden -m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-black"
                    onClick={() => setMobileMenuOpen(true)}
                >
                    <span className="sr-only">Open main menu</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
            </nav>

            {/* Mobile Navigation */}
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)}>
                <div className="fixed inset-0 z-50 bg-black bg-opacity-50" />
                <Dialog.Panel className="fixed inset-y-0 left-0 z-50 w-[70%] bg-white px-6 py-6 sm:max-w-sm">
                    <div className="flex items-center justify-between">

                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-black"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>

                    <div className="mt-20 flow-root">
                        <div className="">
                            {/* Mobile Nav Links */}
                            <div className="py-6">
                                {NavLinks.map(({title, url}) => (
                                    <a
                                        key={title}
                                        href={url}
                                        className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 ${
                                            location.pathname === url ? 'text-black' : 'text-black'
                                        } hover:text-primary`}
                                    >
                                        {title}
                                    </a>
                                ))}
                            </div>

                            {/* Mobile Call to Action */}
                            <div className="lg:hidden ">
                                <button
                                    className="py-2 px-4 bg-primary text-sm text-white rounded"
                                >
                                    Contact Us
                                </button>
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    );
};

export default Header;